@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');
*{
    font-family: "Figtree", sans-serif !important;

}
.dividernav{
    height: 0.6px;
    background-color: #4a4a4a;
}
body{
    background-color: #c7c7c7 !important;
   
    overflow-x: hidden !important;
  }
  @font-face {
    font-family: 'MyCustomFont';
    src: url('./fonts/Sidewalks-Regular\ TTF.ttf') format('truetype');
  }
  .LOGO_TEXT{
    font-family: 'MyCustomFont' !important;
    font-size: 55px;
    font-weight: 500;
    color: #000000;
  }

  .LOGO_TEXTM{
    font-family: 'MyCustomFont' !important;
    font-size: 23px;
    font-weight: 500;
    color: #fff;
  }

  .hero_background {
    background-image: url('./website_bg.jpg');
    background-size: cover;       /* Ensures the image covers the entire element */
    background-position: center;  /* Centers the image */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    background-attachment: fixed; /* Keeps the image fixed while scrolling */
    width: 100%;                  /* Ensures the element spans the full width */
    height: 100vh;                /* Ensures the element spans the full height of the viewport */
  }
  
  .hero_background_mobile{
    background-image: url('./Mobile\ Landing\ Page\ \(1\).png');
    background-color: #333333;
    background-size: cover;       /* Ensures the image covers the entire element */
    background-position: center;  /* Centers the image */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    background-attachment: fixed; /* Keeps the image fixed while scrolling */
    width: 100%;            

    height: 100vh;                /* Ensures the element spans the full height of the viewport */
  }

  

  .head_text{
    background-color: transparent;
   color: #fff;
   
font-size: 120px;
    font-style: normal;
    font-weight: 700;
    height: auto;


    line-height: normal;



    width: 1506px;
  }


  .head_textm{
    background-color: transparent;
   color: #fff;
   
font-size: 30px;
    font-style: normal;
    font-weight: 700;


width: 300px;




   
  }


  .span_three_words{
 
  background: linear-gradient(277deg, #becbda 48.33%, #6285a3 93.16%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;

  margin: 0;
  }


.tech_hand{
color: #fff;
font-size: 30px;
font-weight: 100;

letter-spacing: -0.04em;
text-align: left;

}
.tech_handm{
  color: #fff;
  font-size: 20px;
  font-weight: 100;
  
  letter-spacing: -0.04em;
  text-align: left;
  
  }
  
  .footer_head{
    color: #fff;
    text-align: start;
}






.Footer_background{
    background-color: #062837;
}




.text_start_footer{
    text-align: left !important;
}

.bargain_footer{
    font-size: 330px;
    font-weight: 800;
    text-align: center;
    color: #001e2b;
}
.bargain_footerm{
  font-size: 90px;
  font-weight: 800;
  text-align: center;
  color: #001e2b;
}

.footer_under_bargin_one{
    font-size: 12px !important;
    font-weight: 300 !important;
    text-align: start;
    color: #BECBDA;
}


.footer_under_bargin_two{
    font-size: 12px !important;
    font-weight: 300 !important;
    text-align: end;
    color: #BECBDA;
}

.footer_under_bargin_onem{
  font-size: 10px !important;
  font-weight: 300 !important;
  text-align: start;
  color: #BECBDA;
}


.footer_under_bargin_twom{
  font-size: 10px !important;
  font-weight: 300 !important;
  text-align: end;
  color: #BECBDA;
}

.background_of_ambition{
    background: linear-gradient(180deg, rgb(190.0000038743019, 203.00000309944153, 218.00000220537186) 22.496964037418365%, rgb(248.0000004172325, 250.00000029802322, 255) 60.834139585494995%, rgb(255, 255, 255) 64.02890086174011%) !important;
    background-color: transparent;

}








.svgs_ambition {
    position: relative;
    text-align: center;

}

.centered {
    position: absolute;
    top: 55%;
    left: 60%;
 
    justify-content: center !important;
    align-items: center !important;
text-align: center !important;
    width: 100%;
    text-align: start !important;
    font-size: 75px;
    color: #4e4e4e97;
    font-weight: 800;

    transform: translate(-50%, -50%);
  }

.scroll-reveal span {
    color: hsla(0, 0%, 55%, 0.2);
    background-clip: text;
 
    background-repeat: no-repeat;
    background-size: 0% 100%;
    background-image: linear-gradient(90deg, #0b212d , #0b212d);
  animation: scroll-reveal linear forwards;
  animation-timeline: view();

}

.scroll-reveal h3 span {
    animation-range-start: cover -10vh;
    animation-range-end: cover 100vh;
}


@keyframes scroll-reveal {
    to {
        background-size: 100% 100%;
    }
}


.multicards_background{
  background-color: #ffffff;
}

  











.head_multi {
  background-color: transparent;
  color: #13171E;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 0px;
  letter-spacing: -2.56px;
  line-height: normal;

  text-align: left;
  top: -1px;
  white-space: nowrap;
  width: auto;
}



.head_multim {
  background-color: transparent;
  color: #13171E;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 0px;
  letter-spacing: -2.56px;
  line-height: normal;

  text-align: left;
  top: -1px;
  white-space: nowrap;
  width: auto;
}
.head_multi_under{
  letter-spacing: -0.96px;
font-size: 19px;
    color: #13171E;
}

.head_multi_underm{
  letter-spacing: -0.96px;
font-size: 14px;
    color: #13171E;
}


.card_bg{
background: #fff;
  border-radius: 24px;
  box-shadow: 0px 0px 50px -10px #00000040;

width: 100%;
 


}


.dep{
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 45px;
  font-weight: 500;
  color: #062837;
}



.dep_date{
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 85px;
  font-weight: 700;
  color: #6285a3;
  font-style: normal;
  letter-spacing: -0.69px;
}

.depm{
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  font-weight: 500;
  color: #062837;
}



.dep_datem{
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 65px;
  font-weight: 700;
  color: #6285a3;
  font-style: normal;
  letter-spacing: -0.69px;
}

.fat_multi_card_text{
  letter-spacing: -1.92px;
  font-size: 40px;
  font-weight: 100 !important;
}




.fat_multi_card_textm{
  letter-spacing: -1.92px;
  font-size: 23px;
  font-weight: 100 !important;
}



.contact_card{
  background: linear-gradient(0deg, #becbda 32%, #f8faff 81.24%, #fff);

}



.card_contact_button{
  border-radius: 24px;
  box-shadow: 0 0 50px -10px rgba(0, 0, 0, 0.25);
  background-color: #f8faff;
  width:100%;
height: 100%;


}
.card_contact_button_mission1{
  border-radius: 10px;
  box-shadow: 0 0 50px -10px rgba(0, 0, 0, 0.25);
  background-color: #062837;
  width:100%;
height: 100%;


}


.card_contact_button_mission2{
  border-radius: 10px;
  box-shadow: 0 0 50px -10px rgba(0, 0, 0, 0.25);
  background-color: #BECBDA;
  width:100%;
height: 100%;


}
.pour_text{
  background-color: transparent;
  color: #13171E;
font-weight: 800;
  font-size: 108px;
  font-style: normal;

  height: auto;

  letter-spacing: -5.12px;
  line-height: normal;

  text-align: right;

}


.pour_textm{
  background-color: transparent;
  color: #13171E;
font-weight: 800;
  font-size: 68px;
  font-style: normal;

  height: auto;

  letter-spacing: -5.12px;
  line-height: normal;
position: relative;
right: 20px;
  text-align: right;

}
.head_multi_under_pour{
  letter-spacing: -0.96px;
  padding: 48px;
font-size: 19px;
    color: #13171E;
}
.head_multi_under_pourm{
  letter-spacing: -0.96px;
  padding: 10px;
font-size: 17px;
    color: #13171E;
}

.padding_button{
  padding-left: 25px;
}


.btn {
  box-sizing: border-box;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #ededed;
  border: 2px solid #062837;
  border-radius: 40px;
  color: #062837;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  margin: 20px;
  padding: 1.2em 2.8em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}
.btn:hover, .btn:focus {
  color: #fff;
  outline: 0;
}


.fourth {
  border-color: #062837;
  color: #062837;
  background-image: -webkit-linear-gradient(95deg,#062837 50%, transparent 50%);
  background-image: linear-gradient(95deg, #062837 50%, transparent 50%);
  background-position: 100%;
  background-size: 400%;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out;
}
.fourth:hover {
  background-position: 0;
}










.btnm {
  box-sizing: border-box;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #ededed;
  border: 2px solid #062837;
  border-radius: 40px;
  color: #062837;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 1;
  margin: 20px;
  padding: 1.2em 2.8em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}
.btnm:hover, .btnm:focus {
  color: #fff;
  outline: 0;
}


.fourthm {
  border-color: #062837;
  color: #062837;
  background-image: -webkit-linear-gradient(95deg,#062837 50%, transparent 50%);
  background-image: linear-gradient(95deg, #062837 50%, transparent 50%);
  background-position: 100%;
  background-size: 400%;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out;
}
.fourthm:hover {
  background-position: 0;
}







.span_three_words {
  display: inline-block;
  transition: transform 0.1s ease-in-out, opacity 0.2s ease-in-out;
  opacity: 0;
}


.background_of_footer_faq{
  background-color:#062837 !important;
  box-shadow: none !important;
  border: none !important;
}











.svgs_ambitionm {
  position: relative;
  text-align: center;
width: 60% !important;
position: relative;
left: 40px;
}






.centeredm {
  position: absolute;
  top: 55%;
  left: 155px;
  justify-content: center !important;
  align-items: center !important;
text-align: center !important;
  width: 290px;
  text-align: start !important;
  font-size: 17px;
  color: #4e4e4e97;
  font-weight: 800;

  transform: translate(-50%, -50%);
}

.scroll-reveal span {
  color: hsla(0, 0%, 55%, 0.2);
  background-clip: text;

  background-repeat: no-repeat;
  background-size: 0% 100%;
  background-image: linear-gradient(90deg, #0b212d , #0b212d);
animation: scroll-reveal linear forwards;
animation-timeline: view();

}

.scroll-reveal h3 span {
  animation-range-start: cover -10vh;
  animation-range-end: cover 100vh;
}


@keyframes scroll-reveal {
  to {
      background-size: 100% 100%;
  }
}

.mission1_head{
  color: #F8FAFF ;
  font-size: 2cap;
  font-weight: 700;
}

.mission1_head_under{
  color: #BECBDA ;
  font-size: 16px;
  font-weight: 400;
}
.mission1_head_under2{
  color: #F8FAFF ;
  font-size: 13px;
  font-weight: 100;
}






.mission2_head{
  color: #062837 ;
  font-size: 31px;
  font-weight: 700;
}

.mission2_head_under{
  color: #13171E ;
  font-size: 19px;
  font-weight: 400;
}
.mission2_head_under2{
  color: #13171E ;
  font-size: 14px;
  font-weight: 100;
}

.background_mission{
  background-color: #fff;
}

.back_card1{
  background: linear-gradient(180deg, rgb(6.000000117346644, 40.00000141561031, 55.00000052154064) 0%, rgb(19.0000007674098, 23.000000528991222, 30.00000011175871) 100%);
  border-radius: 24px;
  box-shadow: 0px 0px 50px -20px #00000040;
}



.back_card2{
  background-color: #becbda !important;
  border-radius: 24px;
  box-shadow: 0px 0px 50px -20px #00000040;
}


.left_texts{
  padding: 20px;
}

.text_head_mission{

  padding: 25px;

  font-weight: 700;
}

.text_head_missionp{
  font-size: 23px;
  padding: 22px;

  font-weight: 100;
}
.text_head_missionpt{
  font-size: 15px;
  padding: 21px;

  font-weight: 300;
}

.mobile_card{
  border-radius: 0px 24px 0px 0px;
  position: relative;
  bottom: 8px;
  background: linear-gradient(180deg, rgb(98.00000175833702, 133.00000727176666, 163.00000548362732) 0%, rgb(149.24583792686462, 176.64065033197403, 208.24999898672104) 100%);
}

.mobile_mockup_image{
  width: 220px;
  position: relative;
  bottom: 17px;
}
.mobile_card{
  height: 300px;
  justify-content: center;
  align-items: center;
  display: grid;
  
}

.mobile_card_two{
  border-radius: 0px 0px 20px 0px;
  position: relative;
  bottom: 8px;
  border-radius: 0px 0px 24px 0px;
  background: linear-gradient(180deg, #a7abb2 0%,#a7abb2 100%);
}

.mobile_mockup_imageu{
  width: 100%;
position: relative;
bottom: 12px;
right: 6px;
border-radius: 0px 0px 24px 0px;

}
.mobile_mockup_imageut{
  width: 100%;
position: relative;
bottom: 10px;
right: 6px;
border-radius: 0px 0px 24px 0px;

}
.mobile_card_two{
  height: 300px;

  
}


.text-head-missionp {
  opacity: 0;
  transform: translateY(20px);
}

.text-head-missionpt {
  opacity: 0;
  transform: translateY(20px);
}




/* MissionDesktop.css */
.text_head_mission {
  transition: width 0.5s ease !important;
}



.text_head_mission2{
color: #062837 !important;
  padding: 25px;

  font-weight: 700;
}




/* MissionDesktop.css */
.text_head_mission2 {
  transition: width 0.5s ease !important;
}




.text_head_missionp2{
  font-size: 27px;
  padding: 22px;
  color: #062837 !important;
  font-weight: 100;
}


.text_head_missionpt2{
  font-size: 15px;
  padding: 21px;
  color: #062837 !important;
  font-weight: 300;
}



.pour_textmt{
  background-color: transparent;
  color: #13171E;
font-weight: 800;
  font-size: 68px;
  font-style: normal;

  height: auto;

  letter-spacing: -5.12px;
  line-height: normal;
position: relative;
right: 0px;
  text-align: center;

}




















































.mobile_card2{
  border-radius: 0px 24px 24px 0px;
  position: relative;
  bottom: 8px;
  height: 600px;
  background: linear-gradient(180deg, rgb(98.00000175833702, 133.00000727176666, 163.00000548362732) 0%, rgb(149.24583792686462, 176.64065033197403, 208.24999898672104) 100%);
}

.mobile_mockup_image2{
  width: 100%;
  position: relative;
  top: 2px;
}
.mobile_mockup_image2t{
  width: 100%;
  position: relative;
  height: 600px;
  top: 2px;
}
.padding_buttont{
align-items: center;
text-align: center;
justify-content: center;
display: flex;
}



.centeredta {
  position: absolute;
  top: 55%;
  left: 60%;

  justify-content: center !important;
  align-items: center !important;
text-align: center !important;
  width: 100%;
  text-align: start !important;
  font-size: 65px;
  color: #4e4e4e97;
  font-weight: 800;

  transform: translate(-50%, -50%);
}


.backofcard2mobile{
  background-color: #000000 !important;
}

.bordermobile_card2{
  border-radius: 20px 20px 0px 0px;
}












.dropdown-menu {
  position: absolute;
  top: 35px;
  left: 0;
  background: #2a2a2a14;
  border: 1px solid #ddd;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.dropdown-menu a {
  padding: 10px 20px;
  color: #000;
  text-decoration: none;
  font-size: 16px;
  transition: background 0.3s ease-in-out;
}

.dropdown-menu a:hover {
  background: #2a2a2a14;
}










.dropdown-menum {
  position: absolute;
  top: 35px;
  left: 0;
  background: #333333;
  border: 1px solid #ddd;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  text-align: start !important;
  display: flex;
  flex-direction: column;
}

.dropdown-menum a {
  padding: 10px 20px;
  color: #ffffff;
  text-decoration: none;
  font-size: 16px;
  transition: background 0.3s ease-in-out;
}

.dropdown-menum a:hover {
  background: #2a2a2a14;
}






.footer-desktop {
  position: fixed;
  bottom: 0;
  width: 100%;
  font-weight: 400;
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
}


.footer-desktopm {
  position: fixed;
  color: #fff;
  bottom: 0;
  width: 100%;
  font-weight: 400;
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
}


.centeritall{
  justify-content: center;
  align-items: center;
  text-align: center;
}





.fixed-bottom-container {
  position: fixed;
  bottom: 70px;
  left: 0;

  width: 100%;

  padding: 0px 0px; /* Adjust padding as needed */

}

/* Adjustments for responsiveness */
@media (max-width: 768px) {
  .fixed-bottom-container {
    bottom: 90px;
    /* Example adjustments for smaller screens */
    padding: 10px 0;
  }
}

.enditall{
  justify-content: end;
  text-align: end;
  
}



/* src/components/VideoBackground.css */
.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1; /* To ensure it's behind other content */
}

.video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the entire container */
  transform: translate(-50%, -50%) scale(0.99); /* Scale down to zoom out */
}



















































































