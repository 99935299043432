:root {
    --bg-01: rgba(10, 10, 10, 1);
    --black-bg: rgba(2, 2, 2, 1);
    --border-01: rgba(31, 31, 31, 1);
    --border-02: rgba(66, 66, 66, 1);
    --primary: rgba(235, 254, 100, 1);
    --secondary: rgba(140, 234, 105, 1);
    --typography-colortext-01: rgba(255, 255, 255, 1);
    --typography-colortext-02: rgba(225, 225, 225, 1);
    --typography-colortext-03: rgba(191, 191, 191, 1);
    --typography-colortext-04: rgba(175, 175, 175, 1);
    --typography-colortext-07: rgba(2, 2, 2, 1);
    --white-bg: rgba(255, 255, 255, 1);
  }


.app__navbar {
    width: 100%;
    display: flex;
background-color: #080a3e00;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem;
    z-index: 1 !important;
    font-family: 'MyCustomFont' !important;
  

}

.app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
}

.app__navbar-logo img {
    width: 60px;
    position: relative;
    left: 1px;
bottom: 5px;

}

.app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
position: relative;
top: 7px;
color: #fff;
text-decoration: none;
    list-style: none;

}

.app__navbar-links li {
    margin: 0 1rem;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
}



.app__navbar-login {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.app__navbar-login a {
    margin: 0 4rem;
    text-decoration: none;
    transition: .5s ease;
}



.app__navbar-login div {
    width: 1px;
    height: 30px;
    background: var(--color-grey);
}

.app__navbar-smallscreen {
    display: none;
}

.app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(20, 20, 20, 0.734);

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow */
    transition: .5s ease;


    flex-direction: column;
    z-index: 5;
}

.app__navbar-smallscreen_overlay .overlay__close {
    font-size: 27px;
    color:#fff;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
}

.app__navbar-smallscreen_links {
    list-style: none;
    position: relative;
    right: 1rem;
}

.app__navbar-smallscreen_links .li {
    margin: 1.8rem;
    cursor: pointer;
    color: #fff;
    text-decoration: none;
    font-size: 1rem;
    text-align: center;
  
}



@media screen and (min-width: 2000px) {
    .app__navbar-logo img {
        width: 110px;
    }
}

@media screen and (max-width: 1150px) {
    .app__navbar-links {
        display: none;
        text-decoration: none;
        color: #fff;
    }

    .app__navbar-smallscreen {
        display: flex;
    }
}

@media screen and (max-width: 650px) {
    .app__navbar {
        padding: 1rem;
    }

    .app__navbar-login {
      
    }

    .app__navbar-logo img {
        width: 100px;
    }
}

.nav-link {
    color: #ffffff !important;
    text-decoration: none !important;
}





.app__navbar-linkss {
    flex: 1;
    display: flex;
    justify-content: end;
    align-items: center;
   position: relative;
   top: 7px;
    color: #fff;
   text-decoration: none;
    list-style: none;

 
}

.app__navbar-linkss li {
    margin: 0 1rem;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
}











.nav-btn {
    align-items: center;
    background: linear-gradient(180deg, rgb(235, 254, 100) 0%, rgb(140, 234, 105) 100%);
    background-color: rgba(255, 255, 255, 1);

    display: inline-flex;
    gap: 8px;
    justify-content: center;
    padding: 8px 24px;
    position: relative;
  }
  
  .nav-btn .launch-app {
    color: var(--typography-colortext-07);
    font-family: "Mabry Pro-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  






  /* Initial styles for the navigation bar */
.app__navbar {
    background-color: transparent; /* Initially transparent background */
    transition: background-color 0.3s; /* Smooth transition effect */
  }
  
  /* Sticky styles for the navigation bar */
  .app__navbar.sticky {
    position: fixed;
    top: 0;


  


    box-shadow: none !important; /* Optional: Add a shadow */






  }
  